import { LOGIN_USER, LOGOUT_USER, LOGIN_USER_APP, LOGOUT_USER_APP } from "../constants/user";
import { isEmpty } from "validator";

let initalState = {
  userProfile: {},
  isAuthenticated: false,
  userAppProfile: {},
  isAppAuthenticated: false
};

const userReducer = (state = initalState, action) => {
  switch (action.type) {
    case LOGIN_USER: 
      {
        const updateState = { ...state };
        updateState.userProfile = action.payload;
        updateState.isAuthenticated = !isEmpty(action.payload.username);
        return updateState;
      }
    case LOGOUT_USER:
      {
        const updateState = { ...state };        
        updateState.userProfile = {};
        updateState.isAuthenticated = false;
        return updateState;
      }
    case LOGIN_USER_APP: 
      {
        const updateState = { ...state };
        updateState.userAppProfile = action.payload;
        updateState.isAppAuthenticated = !isEmpty(action.payload.username);
        return updateState;
      }
    case LOGOUT_USER_APP:
      {
        const updateState = { ...state };        
        updateState.userAppProfile = {};
        updateState.isAppAuthenticated = false;
        return updateState;
      } 
    default:
      return state;
  }
};
export default userReducer;