import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAppAuthorize } from '../../utils/isLogin'
import jwtDecode from 'jwt-decode'

const LoginGuardApp = ({ component: Component, path }) => {
  
  return (
    <Route
      to={path}
      render={routeProps => {    
        const user = localStorage.getItem("userAppLogin")
        if (user) {
          const company = JSON.parse(user)['company']
          if (company === 'PM') {
            const decoded = jwtDecode(localStorage.jwtTokenApp)
            const pathname = routeProps.location.pathname
            if (isAppAuthorize(pathname)) {
              return <Component {...routeProps} />;
            }
            else {
              alert('Bạn không có quyền truy cập !')
              return <Redirect to={"/#/"} />
            }
          }
        }
        alert('Vui lòng đăng nhập !');
        return <Redirect to="/LoginApp" />
      }}
    />
  );
};

export default LoginGuardApp;
